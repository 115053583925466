/* SubChart.css */

.sub-chart-container {
  width: 300px;
  height: 400px;
  background-color: rgba(17, 17, 17, 0.91);
  border: 1px solid #c0c0c0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  color: #a19d9d;
  cursor: move;

  padding: 0; /* Remove any default padding */
}

.sub-chart-header {
  background-color: #050505;
  color: #fff;
  padding: 2px; /* Reduced padding */
  font-weight: bold;
  border-radius: 30px 30px 0 0;
  margin-bottom: 0px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  width: 100%; /* Ensure the header spans the entire width */
  box-sizing: border-box; /* Include padding in the width */
}

.sub-chart-title {
  font-size: 16px; /* Adjust font size as needed */
  margin-left: 10px;
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
  white-space: nowrap; /* Prevent text wrapping */
}

.sub-chart-box {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-radius: 0 0 10px 10px;
  overflow: auto;
  padding: 10px;
  margin: 0; /* Remove any default margin */
}

.sub-chart-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arrow-buttons-container,
.year-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust as needed */
}

.button {
  background-color: black;
  color: white;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button:hover {
  background-color: #c0c0c0;
  color: black;
  border-color: black;
}
