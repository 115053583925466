.draggable-item {
    width: 600px;
    height: 800px;
    background-color: rgba(17, 17, 17, 0.91);  
    border: 1px solid #e067058e; /* Orange border */
    border-radius: 30px; /* Border radius */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #a19d9d; /* Font color for text */
    cursor: move; /* Set cursor to indicate draggable */
  }
  