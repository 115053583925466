.admin-container {
    width: 95vw;
    height: 95vh;
    overflow: hidden; /* Hide overflow content */
  }
  
  .admin-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover entire container */
  }
  