/* HomeSidebar.css */
.home-sidebar {
  background-color: #000; /* Use a solid black background for stark contrast */
  width: 75px;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column; /* Stack icons vertically */
  align-items: center; /* Center icons horizontally */
  margin: 10px 10px 0px 10px; /* Add 10px margins around the sidebar */
  border-radius: 0; /* Remove border radius for sharp edges */
  border: 2px solid #fff; /* Add a bold white border */
  color: #fff; /* Ensure icons are visible on the dark background */
  font-family: 'Courier New', Courier, monospace; /* Use a retro monospace font */
  text-transform: uppercase; /* Use uppercase text for a bold look */
  }
  
  .orange-icon {
    color: #e06805;
    margin: 15px; /* Add margin between stacked icons */
  }
  
  .large-icon {
    font-size: 1.5rem; /* Define the larger font size */
  }
  