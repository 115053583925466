.mods-container {
    background-color: #000;
    color: #fff;
    padding: 2vh;
    border-radius: 0;
    border: 2px solid #fff;
    font-family: 'Courier New', Courier, monospace;
    max-height: 50vh;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
}

.mods-container h1 {
    font-size: 2.5vh;
    margin: 1vh 0;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: #000;
    z-index: 1;
    padding: 1vh 0;
}

.mods-search-bar {
    width: 50%;
    padding: 0.5vh;
    margin: 1vh auto;
    border-radius: 0;
    border: 2px solid #fff;
    background-color: #111;
    color: #fff;
    font-size: 1.5vh;
    outline: none;
    display: block;
    position: sticky;
    top: 6vh;
    z-index: 1;
}

.mods-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 36vh;
    overflow-y: auto;
    padding-top: 2vh;
}

.mods-list::-webkit-scrollbar {
    display: none;
}

.mods-list-item {
    margin-bottom: 0.5vh;
    font-size: 2vh;
    padding: 1vh;
    border-radius: 0;
    background-color: #111;
    border: 2px solid #fff;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    position: relative;
}

.mods-list-item:hover {
    background-color: #333;
}

.mods-list-item strong {
    color: #fff;
    flex: 1;
    display: inline-block;
}

.mods-loading {
    text-align: center;
    font-size: 1.75vh;
    color: #fff;
    margin-top: 2vh;
}
