.default-header {
  position: relative;
  background-color: #000; /* Use a solid black background for stark contrast */
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin: 20px 10px;
  border-radius: 0; /* Remove border-radius for sharp edges */
  border: 2px solid #fff; /* Add a bold white border */
  color: #fff; /* Ensure text is visible on the dark background */
  font-family: 'Courier New', Courier, monospace; /* Use a retro monospace font */
  text-transform: uppercase; /* Use uppercase text for a bold look */
}
