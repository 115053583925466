/* Home.css */

/* Styles for home-container */
.home-container {
  display: flex;
  height: 100vh;
  background-color: #111; /* Solid dark background */
  background-image: 
      linear-gradient(90deg, rgba(255, 69, 0, 0.15) 1px, transparent 1px),
      linear-gradient(rgba(255, 69, 0, 0.15) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 69, 0, 0.05) 1px, transparent 1px),
      linear-gradient(rgba(255, 69, 0, 0.05) 1px, transparent 1px);
  background-size: 40px 40px, 40px 40px, 10px 10px, 10px 10px;
  background-position: 0 0, 0 0, 0 0, 0 0;
  background-repeat: no-repeat;
  overflow-y: hidden;
  }
  
  /* Styles for sidebar */
  .home-sidebar {
    width: 75px;
    height: 100%;
    background-color: rgba(17, 17, 17, 0.91);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    overflow: hidden;
  }
  
  .orange-icon {
    color: #e06805;
    margin: 15px;
  }
  
  .large-icon {
    font-size: 1.5rem;
  }
  
  /* Styles for dashboard content */
  .dashboard-content {
    flex-grow: 1;
    display: flex; /* Add flex display */
    align-items: flex-start; /* Align items to the start (top) of the container */
    overflow: auto; /* Add overflow scrolling for dashboard content if needed */
    padding: 20px;
  }
  
  /* Styles for Twitch button */
  .twitch-button {
    width: 150px;
    height: 200px;
    background-color: #000; /* Solid black background */
    border: 2px solid #fff; /* Bold white border */
    border-radius: 0; /* Removed border radius for sharp edges */
    margin-top: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff; /* White text */
    font-size: 1rem; /* Font size */
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    text-transform: uppercase; /* Uppercase text */
    cursor: pointer; /* Cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Transition effects */
    text-decoration: none; /* Remove underline from links */
}

.twitch-button:hover {
  background-color: #333; /* Dark grey background on hover */
  color: #ff4500; /* Orange text on hover */
  border-color: #ff4500; /* Orange border on hover */
}
  
  .twitch-icon {
    font-size: 2.5rem;
    color: #6441a5;
    margin-bottom: 10px; /* Add margin below the icon */
    margin-left: 15px;
    margin-top: 15px;
  }
  
  .follower-count {
    font-size: 3rem;
    margin-top: 20px; /* Adjust margin for alignment */
    margin-bottom: 5px; /* Add margin below the count */
  }
  
  .follower-text {
    font-size: 0.6rem;
    color: #aaa;
    margin-top: 5px;
    margin-left: 15px;
  }
  
  /* Styles for YouTube button */
  .youtube-button {
    width: 150px;
    height: 200px;
    background-color: #000; /* Solid black background */
    border: 2px solid #fff; /* Bold white border */
    border-radius: 0; /* Removed border radius for sharp edges */
    margin-top: 50px;
    margin-left: 50px; /* Adjust left margin to align with Twitch button */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff; /* White font color */
    font-size: 1rem; /* Font size */
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    text-transform: uppercase; /* Uppercase text */
    cursor: pointer; /* Cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Transition effects */
    text-decoration: none; /* Remove underline from links */

  }
  
  
  .youtube-button:hover {
    background-color: #333; /* Dark grey background on hover */
    color: #ff4500; /* Orange text on hover */
    border-color: #ff4500; /* Orange border on hover */
  }
  
  .youtube-icon {
    font-size: 2.5rem;
    color: #FF0000; /* YouTube red color */
    margin-bottom: 10px; /* Add margin below the icon */
    margin-left: 15px;
    margin-top: 15px;
  }
  
  .subscriber-count {
    font-size: 3rem;
    margin-top: 20px; /* Adjust margin for alignment */
    margin-bottom: 5px; /* Add margin below the count */
  }
  
  .subscriber-text {
    font-size: 0.6rem;
    color: #aaa; /* Light gray color for text */
    margin-top: 5px;
    margin-left: 15px;
  }
  .info-box {
    position: absolute; /* Position the box absolutely */
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px; /* Adjust width as needed */
    background-color: #000; /* Solid black background */
    border: 2px solid #fff; /* Bold white border */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff; /* White text */
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    text-transform: uppercase; /* Uppercase text */
  }

/* Styles for the profile button */
.profile-button {
    position: absolute; /* Position the button */
    top: 0; /* Align the button to the top */
    right: 0; /* Align the button to the right */
    background: none; /* Remove background */
    border: none; /* Remove border */
    cursor: pointer; /* Set cursor to pointer */
    color: #fff; /* Button text color */
  }
  
  /* Styles for the dropdown menu */
  .dropdown {
    position: absolute;
    top: 45px; /* Adjust the top position to show the dropdown below the profile button */
    right: 0;
    background-color: #000; /* Solid black background */
    border: 2px solid #fff; /* Bold white border */
    border-radius: 0; /* Removed border radius for sharp edges */
    box-shadow: 4px 4px 0 #ff4500; /* Sharp, contrasting orange shadow */
    display: none;
    z-index: 1000;
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    color: #fff; /* White text */
    text-transform: uppercase; /* Uppercase text */
  }
  
  .dropdown.show {
    display: block;
  }
  
  /* Other styles for dropdown items and button inside dropdown */
  .dropdown-items {
    padding: 10px; /* Add padding to dropdown items */
  }
  
  .dropdown button {
    margin-bottom: 5px; /* Add margin between dropdown items */
  }

  .calendar-container {
    width: 100%; /* Ensure the calendar container takes full width of info-box */
    max-width: 1000px; /* Set a maximum width if needed */
  }