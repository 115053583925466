.monthly-growth-container {
  background-color: #000; /* Solid black background to match the button */
  color: #ffffff; /* White text */
  padding: 1vh; /* Reduced padding using vh units */
  border-radius: 0; /* Remove border radius for sharp edges */
  border: 2px solid #fff; /* Bold white border to match the button */
  font-family: 'Roboto', sans-serif; /* Match the font to Twitch followers (change 'Roboto' to the exact font if different) */
  height: 35vh; /* Adjusted height */
  width: 40vw; /* Adjusted width */
  overflow-y: auto; /* Enable vertical scroll */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
  position: relative; /* Ensure child elements position correctly */
  margin: 0; /* Center the container */
  display: flex;
  flex-direction: column;
}

.monthly-growth-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.monthly-growth-heading {
  font-size: 2.5vh; /* Reduced font size for the heading using vh units */
  margin: 1vh 0; /* Adjusted vertical margins using vh units */
  text-align: center;
  font-family: 'Courier New', Courier, monospace; /* Retro monospace font */

  position: sticky;
  top: 0;
  background-color: #000;
  z-index: 1;
  padding: 1vh 0;
}

.monthly-growth-chart-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthly-growth-loading {
  text-align: center;
  font-size: 1.75vh; /* Font size using vh units */
  color: #fff; /* White loading text */
  margin-top: 2vh; /* Margin using vh units */
}

.monthly-growth-loading::after {
  content: 'Initializing';
  animation: monthlyGrowthLoading 2s infinite;
}

@keyframes monthlyGrowthLoading {
  0%, 20% {
      content: 'Initializing';
  }
  40% {
      content: 'Initializing_';
  }
  60% {
      content: 'Initializing__';
  }
  80% {
      content: 'Initializing___';
  }
  100% {
      content: 'Initializing____';
  }
}
