/* TWBan.css */

/* Container and General Styles */
.ban-container {
    background-color: #000; /* Solid black background */
    color: #fff; /* White text */
    padding: 2vh; /* Reduced padding using vh units */
    border-radius: 0; /* Removed border radius for sharp edges */
    border: 2px solid #fff; /* Bold white border */
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    max-height: 50vh; /* Adjusted maximum height using vh units */
    overflow-y: hidden; /* Disable vertical scroll for container */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
    position: relative; /* Ensure child elements position correctly */
}

.ban-heading {
    font-size: 2.5vh; /* Reduced font size for the heading using vh units */
    margin: 1vh 0; /* Adjusted vertical margins using vh units */
    text-align: center;
    position: sticky;
    top: 0;
    background-color: #000;
    z-index: 1;
    padding: 1vh 0;
}

.ban-search-bar {
    width: 50%; /* Reduced width */
    padding: 0.5vh; /* Reduced padding */
    margin: 1vh auto; /* Center horizontally and adjusted margins */
    border-radius: 0; /* Removed border radius for sharp edges */
    border: 2px solid #fff; /* Bold white border */
    background-color: #111;
    color: #fff;
    font-size: 1.5vh;
    outline: none;
    display: block; /* Center horizontally */
    position: sticky;
    top: 6vh; /* Adjust to account for the height of the header */
    z-index: 1;
}

.ban-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 36vh; /* Increased the height of the scrolling area */
    overflow-y: auto; /* Enable vertical scroll */
    padding-top: 2vh; /* Additional padding to account for the sticky header and search bar */
}

.ban-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.ban-list-item {
    margin-bottom: 0.5vh; /* Reduced margin using vh units */
    font-size: 2vh; /* Increased font size using vh units */
    padding: 1vh; /* Increased padding using vh units */
    border-radius: 0; /* Removed border radius for sharp edges */
    background-color: #111; /* Solid dark background */
    border: 2px solid #fff; /* Bold white border */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    display: flex; /* Use flexbox for layout */
    align-items: left; /* Align items to the left */
    position: relative;
    perspective: 1000px;
}

.ban-list-item:hover {
    background-color: #333; /* Lighter dark background on hover */
}

.ban-list-item strong {
    color: #fff; /* White strong text */
    flex: 1; /* Take up remaining space */
    display: inline-block;
}

.ban-flip-card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.ban-list-item:hover .ban-flip-card {
    transform: rotateY(180deg);
}

.ban-flip-card-front, .ban-flip-card-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ban-flip-card-front {
    z-index: 2; /* Ensure front is on top */
}

.ban-flip-card-back {
    transform: rotateY(180deg);
    background-color: #111; /* Solid dark background */
    color: #fff;
    z-index: 1; /* Ensure back is behind front */
}

.ban-list-item:hover .ban-flip-card-front {
    visibility: hidden;
}

.ban-list-item:hover .ban-flip-card-back {
    visibility: visible;
}


