/* StreamChart.css */

/* Style for the chart container */
.chart-container {
  width: 600px; /* Set the width of the dashboard wrapper */
  height: 400px; /* Set the height of the dashboard wrapper */
  overflow: hidden; /* Hide overflow */
  border: 1px solid #c0c0c0; /* Add an orange border */
  border-radius: 30px; /* Add rounded corners */
  box-sizing: border-box; /* Include border in width and height calculations */
  background-color: rgba(17, 17, 17, 0.91);
  }
  
  /* Style for the chart canvas container */
  .chart-canvas {
    max-width: 100%; /* Adjust the maximum width as needed */
    height: 200px; /* Adjust the height as needed */
  }
  
  .chart-header {
    color: white;
    background-color: black;
    margin-top: 0;
    padding: 15px;
    margin-bottom: 0px;
    align-items: center;
    border-radius: 30px;
  }

.chart-button {
  background-color: black;
  color: white;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  
}