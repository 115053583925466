


.Twitch-Views {
  width: 200px;
  height: 100px;
  background: linear-gradient(to top, #242323, #0f0f0f); /* Gradient background */  
  border: 1px solid #c0c0c0; /* Orange border */
  border-radius: 10px; /* Border radius */
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a19d9d; /* Font color for text */
}

.header-Twitch-Views {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.icon-and-views {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
}

.view-count {
  font-size: 24px;
  margin-left: 10px;
}
