.addComponent-button {
  /* AddComponent button styles */
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.added-component {
  /* Dynamically added component styles */
  width: 300px; /* Set the width of the dashboard wrapper */
  height: 400px; /* Set the height of the dashboard wrapper */
  overflow: hidden; /* Hide overflow */
  border: 1px solid #c0c0c0; /* Add an orange border */
  border-radius: 30px; /* Add rounded corners */
  box-sizing: border-box; /* Include border in width and height calculations */
  background-color: rgba(17, 17, 17, 0.91);
}

.chart-container{
  width: 600px; /* Set the width of the dashboard wrapper */
  height: 400px; /* Set the height of the dashboard wrapper */
  overflow: hidden; /* Hide overflow */
  border: 1px solid #c0c0c0; /* Add an orange border */
  border-radius: 30px; /* Add rounded corners */
  box-sizing: border-box; /* Include border in width and height calculations */
  background-color: rgba(17, 17, 17, 0.91);

}

.filter-menu {
  background-color: #222; /* Darker grey as primary color */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Increased shadow for depth */
  padding: 20px;
  width: 300px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  color: #fff; /* White text color */
}

.filter-menu p {
  margin-bottom: 10px; /* Added margin between paragraphs */
}

.filter-menu select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444; /* Slightly lighter grey for borders */
  background-color: #444; /* Dark grey background for selects */
  color: #fff; /* White text color */
  margin-bottom: 15px; /* Added margin between selects */
}

.filter-menu input[type="text"] {
  width: calc(100% - 22px); /* Adjusted input width */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444; /* Slightly lighter grey for borders */
  background-color: #444; /* Dark grey background for input */
  color: #fff; /* White text color */
  margin-bottom: 15px; /* Added margin between input and button */
}

.filter-menu button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333; /* Slightly lighter grey for button background */
  color: #fff; /* White text color */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-menu button:hover {
  background-color: rgba(17, 17, 17, 0.91);
}

.cc-header{
  background-color: #050505; /* Dark background color for the header */
  color: #fff; /* White text color for the header */
  padding: 15px; /* Add padding to the header */
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: 0 0 1px 0 solid #c0c0c0;

}

.silver-text {
  color: silver;
}

.bold-text {
  font-weight: bold;
}