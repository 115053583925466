/* MainArea.css */

/* Style for MainArea component */
.main-area {
  flex-grow: 1; 
  background-color: transparent; 
  height: calc(100vh - 115px); 
  border-radius: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Adjust alignment as needed */
  
}

.draggable-area {
  margin-top: 10px;
  margin-left: 20px;
  width: 1800px; /* Adjust the width as needed */
  height: calc(100vh - 140px); /* Adjust the height as needed */
  border: 2px dashed #ccc; /* Optional: Add a border to visualize the bounds */
  position: relative; /* Ensure the parent container has a position */
  overflow: hidden; /* Prevent items from overflowing outside the bounds */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}


.dropdown-header {
  cursor: pointer;
  color: orange;
  position: absolute; /* Position the toggle button */
  top: 6%; /* Adjust vertical placement */
  right: 1700px; /* Adjust horizontal placement */
  transform: translateY(-50%);
  z-index: 2; /* Ensure the dropdown header is above other content */
}

.dropdown-container {
  position: relative; /* Ensure relative positioning for absolute children */
}

.dropdown-header {
  cursor: pointer;
}

.dropdown-menu {
  position: fixed;
  top: 80px;
  background-color: grey;
  border: 1px solid #c0c0c0;
  padding: 10px;
  z-index: 2;
  right: 1200px;
  flex-direction: column; /* Set to column for vertical list */
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  margin-bottom: 10px; /* Add margin between list items */
}

.dropdown-menu button {
  display: block; /* Display buttons as block elements for vertical layout */
  width: 100%; /* Full width buttons */
  background-color: transparent;
  border: none;
  cursor: pointer;
}



/* Background Color: Whore House Piss Stain #e9e2d7*/