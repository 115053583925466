.header {
  position: relative;
  background-color: rgba(17, 17, 17, 0.91);
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin: 20px 10px;
  border-radius: 10px;
}

.profile-button {
  cursor: pointer;
  color: #e06805;
  font-size: 1.5rem;
  margin-left: 10px;
}

.header-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(17, 17, 17, 0.91);
  border: 1px solid #e06805;
  border-radius: 5px;
  padding: 10px;
  display: none;
  min-height: 200px;
  width: 250px; /* Adjusted width */
  z-index: 100;
}

.header-dropdown-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-dropdown-items > div {
  margin-right: 10px;
}

.header-dropdown.show.show {
  display: block;
}

.twitch-info {
  display: flex;
  align-items: center;
}
