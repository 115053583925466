/* Container Styles */
.twitch-page {
  display: flex;
  flex-direction: column; /* Arrange components vertically */
  background-color: #111111;
  background-image: 
      linear-gradient(90deg, rgba(255, 69, 0, 0.15) 1px, transparent 1px),
      linear-gradient(rgba(255, 69, 0, 0.15) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 69, 0, 0.05) 1px, transparent 1px),
      linear-gradient(rgba(255, 69, 0, 0.05) 1px, transparent 1px);
  background-size: 40px 40px, 40px 40px, 10px 10px, 10px 10px;
  background-position: 0 0, 0 0, 0 0, 0 0;
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide overflow in the container */
}

.page-content {
  display: flex;
  flex-direction: row; /* Arrange children horizontally */
  flex: 1; /* Allow it to expand and fill available space */
  overflow: hidden; /* Prevent overflow */
  padding: 10px; /* Padding inside the container */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.twitch-content {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Gap between items */
  width: 100%; /* Full width of its container */
  padding: 0; /* No additional padding */
  box-sizing: border-box; /* Include padding in width calculations */
}

