/* TFollower.css */

.dashboard-wrapper {
  width: 300px; /* Set the width of the dashboard wrapper */
  height: 400px; /* Set the height of the dashboard wrapper */
  overflow: hidden; /* Hide overflow */
  border: 1px solid #c0c0c0; /* Add an orange border */
  border-radius: 30px; /* Add rounded corners */
  box-sizing: border-box; /* Include border in width and height calculations */
  background-color: rgba(17, 17, 17, 0.91);
}

.dashboard-header {
  background-color: #050505; /* Dark background color for the header */
  color: #fff; /* White text color for the header */
  padding: 15px; /* Add padding to the header */
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: 0 0 1px 0 solid #c0c0c0;
}

ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  overflow-y: auto; /* Enable scrolling when content overflows */
  height: calc(100% - 48px); /* Adjust height to fit content within the wrapper */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

ul::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

li {
  padding: 10px; /* Add padding to list items */
}

.dashboard-box .follower-label {
  font-weight: bold; /* Make follower label bold */
  color: #e9e2d7; /* Lighter color for follower name */
}

.followed-date {
  color: #a19d9d; /* Font color for text */
}
