/* App.css */
.app {
  display: flex;
  flex-direction: column; /* Arrange components vertically */
  background-color: #111111;
  background-image: url('./Background4.png'); /* Import your background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
  overflow-y: hidden; /* Prevent vertical scrolling */
}

/* App.css */
.content {
  display: flex;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
}

.content {
  flex: 1; /* Allow content to fill remaining space */
}
