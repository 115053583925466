/* Container and General Styles */
.container {
    background-color: #000; /* Solid black background */
    color: #fff; /* White text */
    padding: 2vh; /* Reduced padding using vh units */
    border-radius: 0; /* Removed border radius for sharp edges */
    border: 2px solid #fff; /* Bold white border */
    font-family: 'Courier New', Courier, monospace; /* Retro monospace font */
    max-height: 50vh; /* Adjusted maximum height using vh units */
    overflow-y: hidden; /* Disable vertical scroll for container */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
    position: relative; /* Ensure child elements position correctly */

}

h1 {
    font-size: 2.5vh; /* Reduced font size for the heading using vh units */
    margin: 1vh 0; /* Adjusted vertical margins using vh units */
    text-align: center;

    position: sticky;
    top: 0;
    background-color: #000;
    z-index: 1;
    padding: 1vh 0;
}

.search-bar {
    width: 50%; /* Reduced width */
    padding: 0.5vh; /* Reduced padding */
    margin: 1vh auto; /* Center horizontally and adjusted margins */
    border-radius: 0; /* Removed border radius for sharp edges */
    border: 2px solid #fff; /* Bold white border */
    background-color: #111;
    color: #fff;
    font-size: 1.5vh;
    outline: none;
    display: block; /* Center horizontally */
    position: sticky;
    top: 6vh; /* Adjust to account for the height of the header */
    z-index: 1;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 36vh; /* Increased the height of the scrolling area */
    overflow-y: auto; /* Enable vertical scroll */
    padding-top: 2vh; /* Additional padding to account for the sticky header and search bar */
}

ul::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

li {
    margin-bottom: 0.5vh; /* Reduced margin using vh units */
    font-size: 2vh; /* Increased font size using vh units */
    padding: 1vh; /* Increased padding using vh units */
    border-radius: 0; /* Removed border radius for sharp edges */
    background-color: #111; /* Solid dark background */
    border: 2px solid #fff; /* Bold white border */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    position: relative;
    perspective: 1000px;
}

li:hover {
    background-color: #333; /* Lighter dark background on hover */
}

li strong {
    color: #fff; /* White strong text */
    flex: 1; /* Take up remaining space */
    display: inline-block;
}

.flip-card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

li:hover .flip-card {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flip-card-front {
    z-index: 2; /* Ensure front is on top */
}

.flip-card-back {
    transform: rotateY(180deg);
    background-color: #111; /* Solid dark background */
    color: #fff;
    z-index: 1; /* Ensure back is behind front */
}

li:hover .flip-card-front {
    visibility: hidden;
}

li:hover .flip-card-back {
    visibility: visible;
}

.loading {
    text-align: center;
    font-size: 1.75vh; /* Reduced font size using vh units */
    color: #fff; /* White loading text */
    margin-top: 2vh; /* Reduced margin using vh units */
}

.loading::after {
    content: 'Initializing';
    animation: loading 2s infinite;
}

@keyframes loading {
    0%, 20% {
        content: 'Initializing';
    }
    40% {
        content: 'Initializing_';
    }
    60% {
        content: 'Initializing__';
    }
    80% {
        content: 'Initializing___';
    }
    100% {
        content: 'Initializing____';
    }
}
